<template>
  <div class="upload-container">
    <button @click="toggleUpload" class="upload-toggle-button">
      <i class="fas fa-upload"></i> 上传文件
    </button>
    <Avatar></Avatar>
    <div v-if="showUpload" class="upload-panel-overlay" @click="toggleUpload">
      <div class="upload-panel" @click.stop>
        <h2>上传文件</h2>
        <div class="file-input-wrapper">
          <input class="file-input" type="file" multiple @change="handleFileUpload" />
        </div>
        <button
          @click="confirmUpload"
          :disabled="isUploading"
          class="upload-button"
          :class="{ 'is-uploading': isUploading }"
        >
          确认上传
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { uploadFiles, savePath, uploadDwg, getTokens } from '@/service/PrjInfo'
import { ElMessage } from 'element-plus'
// import  uploadFile  from '@/service/uploadImage'
import * as qiniu from 'qiniu-js'
import axios from 'axios'
import imageCompression from 'browser-image-compression'
import Avatar from '@/components/Avatar.vue'
const route = useRoute()
const curRoute = ref(route.name)
const isUploading = ref(false)
let uploadToken = ''

watch(
  () => route.name,
  (newName) => {
    curRoute.value = newName
  }
)
const projectId = route.params.id
const selectedFiles = ref([])
const showUpload = ref(false)
const bucketDomain = 'https://image.surveyexcel.com'

// function setCookie(name, value, days) {
//   const date = new Date();
//   date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // 设置过期时间
//   const expires = "expires=" + date.toUTCString();
//   document.cookie = name + "=" + encodeURIComponent(value) + ";" + expires + ";path=/";
// }

// async function login(userName, password) {
//   const url = '/getToken/login/matchCode'; // 替换为实际的登录接口
//   try {
//     const response = await axios.post(url, {
//       userName,
//       redirect: "",
//       password,
//     });
//     const { uid, realName } = response.data;
//     setCookie('uid', uid, 7);
//     setCookie('realname', realName, 7);
//   } catch (error) {
//     console.error('登录失败:', error);
//     throw error;
//   }
// }

// async function getToken() {
//   const url = '/getToken/token';
//   try {
//     const response = await axios.get(url, {
//       withCredentials: true, // 自动携带浏览器中存储的 Cookie
//     });
//     return response?.data?.uptoken;
//   } catch (error) {
//     console.error('Error fetching upload token:', error);
//     throw error;
//   }
// }

// let uploadToken = ''
// async function getUploadToken(user, psw) {
//   try {
//     await login(user, psw);
//     uploadToken = await getToken();
//     return uploadToken
//   } catch (error) {
//     console.error('操作失败:', error);
//   }
// }
// getUploadToken('huangleibin', 'huanglb_123')
setInterval(async () => { 
  uploadToken = await getTokens()
}, 1000 * 60 * 10)

const confirmUpload = async () => {
  isUploading.value = true
  try {
    if (route.name === 'Document') {
      await uploadFile()
    } else if (route.name === 'Photo') {
      await upload()
    } else if (route.name === 'Dwg') {
      await uploadDwgs()
    }
  } catch (error) {
    console.error('上传失败', error)
  } finally {
    isUploading.value = false // 上传完成后，恢复按钮状态
  }
}

const toggleUpload = () => {
  showUpload.value = !showUpload.value
}
const handleFileUpload = (event) => {
  selectedFiles.value = event.target.files
}

const uploadInfo = () => {
  return ElMessage({
    message: '文件上传中，请稍后...',
    type: 'info',
    duration: 0,
    icon: 'el-icon-loading',
    customClass: 'custom-message'
  })
}

// 上传dwg文件
const uploadDwgs = async () => {
  if (selectedFiles.value.length === 0) {
    ElMessage.warning('请选择需要上传的文件')
    return
  }
  for (const file of selectedFiles.value) {
    const fileName = file.name.toLowerCase()
    if (!fileName.endsWith('.dwg')) {
      ElMessage.warning('只能上传DWG文件，请重新选择。')
      return
    }
  }
  const info = uploadInfo()

  for (let file of selectedFiles.value) {
    const formData = new FormData()
    formData.append('file', file)
    uploadDwg(formData)
      .then(async (res) => {
        if (!res.data) return
        let url = res.data.qiniuPath,
          origin_url = res.data.filename
        await savePath([[file.name, file.type, origin_url, url, 2, projectId]])
        emit('filesUploaded')
        showUpload.value = false
        info.close()
      })
      .catch((err) => {
        info.close()
        showUpload.value = false
        ElMessage.warning('文件上传失败，请重试')
      })
  }
}

// 上传图片
const upload = async () => {
  if (selectedFiles.value.length === 0) {
    ElMessage.warning('请选择需要上传的文件')
    return
  }

  // 检查是否所有文件都是图片
  const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp', 'image/tiff']
  for (const file of selectedFiles.value) {
    if (!imageTypes.includes(file.type)) {
      ElMessage.warning('只能上传图片文件，请重新选择。')
      return
    }
  }
  const messageInstance = ElMessage({
    message: '文件上传中，请稍后...',
    type: 'info',
    duration: 0,
    icon: 'el-icon-loading',
    customClass: 'custom-message'
  })

  const formData = new FormData()
  const projectName = selectedFiles.value[0].name.split('.')[0]
  formData.append('projectName', projectName)
  formData.append('projectId', projectId)

  // 压缩图片并加入到 FormData 中
  for (const file of selectedFiles.value) {
    const compressedFile = await imageCompression(file, {
      maxSizeMB: 1, // 最大压缩到 1MB
      maxWidthOrHeight: 800, // 最大宽高 800px
      useWebWorker: true
    })

    // 创建新的 File 对象，并保持原始文件名不变
    const newFile = new File([compressedFile], file.name, {
      type: compressedFile.type,
      lastModified: Date.now() // 更新最后修改时间
    })

    formData.append('files', newFile)
  }

  try {
    const response = await uploadFiles(formData)
    ElMessage.success('文件上传成功')
    showUpload.value = false
    if (response.data) {
      const res = response.data.map(([a, b, c, d, e]) => [a, b, '',c, d, e])
      await savePath(res)
    }
    emit('filesUploaded')
  } catch (error) {
    console.error('Error uploading files:', error)
    ElMessage.warning('文件上传失败，请重新上传')
  } finally {
    messageInstance.close()
    // isUploading.value = false
  }
}

// 上传文档
const uploadFile = async () => {
  if (selectedFiles.value.length === 0) {
    ElMessage.warning('请选择需要上传的文件')
    return
  }
  // 检查是否所有文件都是 Word、PDF 或 Excel 文档
  const documentTypes = [
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/pdf', // .pdf
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx
  ]

  for (const file of selectedFiles.value) {
    if (!documentTypes.includes(file.type)) {
      ElMessage.warning('只能上传Word、PDF、Excel文档，请重新选择。')
      return
    }
  }
  // const uploadToken = "hdTc-x9-BbHPheZ1-pCyjWMAf0OJoPVvTj9WTy9G:gTpBuHeONJC-dZ6Yqx1m2_hRgqE=:eyJzY29wZSI6Im1ldHJvaW1hZ2VzIiwiZGVhZGxpbmUiOjE3MzI1ODg1NzN9"
  const messageInstance = ElMessage({
    message: '文件上传中，请稍后...',
    type: 'info',
    duration: 0,
    icon: 'el-icon-loading',
    customClass: 'custom-message'
  })
  for (let file of selectedFiles.value) {
    const config = {
      useCdnDomain: true,
      region: qiniu.region.z0
    }
    const putExtra = {
      fname: file.name,
      params: {},
      mimeType: null
    }

    const observable = qiniu.upload(file, file.name, uploadToken, putExtra, config)

    observable.subscribe({
      next(res) {
        console.log('Uploading:', res)
      },
      error(err) {
        console.error('Upload Error:', err)
      },
      async complete(res) {
        const fileUrl = `${bucketDomain}/${res.key}`
        messageInstance.close()
        ElMessage.success('文件上传成功')
        showUpload.value = false
        await savePath([[file.name, file.type, '', fileUrl, 1, projectId]])
        emit('filesUploaded')
      }
    })
  }
}
const emit = (event) => {
  const customEvent = new CustomEvent(event)
  window.dispatchEvent(customEvent)
}

onMounted(async () => {
  uploadToken = await getTokens()
})
</script>

<style lang="scss" scoped>
.upload-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .upload-toggle-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    margin-right: 80px;

    i {
      margin-right: 8px;
    }

    &:hover {
      background-color: #45a049;
    }
  }

  .upload-panel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .upload-panel {
      background-color: white;
      padding: 30px;
      border-radius: 10px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
      max-width: 400px;
      width: 100%;
      text-align: center;

      h2 {
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 24px;
        color: #333;
      }

      .file-input-wrapper {
        margin: 20px 0;

        .file-input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          cursor: pointer;
        }
      }

      .upload-button {
        background-color: #007bff;
        color: white;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }
      .is-uploading {
        background-color: #ccc;
      }
    }
  }
}
</style>
