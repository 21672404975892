import axios from 'axios'
import type { ITag, IBaseStation } from '@/models/prjInfo'
import router from '@/router'

// 使用 axios 拦截器自动添加 Authorization 头
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 添加响应拦截器
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // 如果响应返回 401 或 403，表示令牌无效或过期
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // 移除失效的 token
      localStorage.removeItem('token');
      // 重定向到登录页面
      router.push({ name: 'Login' });
    }
    return Promise.reject(error);
  }
);

export const getPrjInfo = async () => {
  try {
    const response = await axios.get('/api/getAllPrj')
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const updatePrjInfo = async (data: any) => {
  try {
    return await axios.post(`/api/newPrj`, {
      data
    })
  } catch (error) {
    console.error(error)
  }
}

export const uploadFiles = async (data: any) => {
  try {
    return axios.post('/upload/uploadfile', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch {
    console.log('上传失败')
  }
}

export const uploadDwg = async (data: any) => {
  try {
    return axios.post('/upload/mxcad/upfile', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    console.log(error)
  }
}

export const savePath = async (data: any) => {
  return axios.post('/api/savePath', { data })
}

export const delPrj = async (id: number) => {
  return axios.delete(`/api/delete?id=${id}`)
}

export const delFile = async (id: number) => {
  return axios.delete(`/api/deleteFile?id=${id}`)
}

export const login = async (username: string, password: string) => {
   try {
     const response = await axios.post('/api/login', { username, password })
     const token = response.data.token
     localStorage.setItem('token', token)
     return response.data
   } catch (error) {
     // 处理错误情况，例如用户名或密码错误
     console.error('登录失败', error)
     throw new Error('登录失败，请检查用户名和密码')
   }
}

export const addCoor = async (data: any) => {
  return axios.post('/api/addCoor', data)
}

export const getCoor = async (id: any) => {
  // return axios.get('/api/getCoor', {
  //   params: {
  //     project_id: id
  //   }
  // })
  const response = await axios.get('/api/getCoor')
  return response.data
}

export const getTokens = async () => {
  const res = await axios.get('/api/getToken')
  return res.data?.token
}